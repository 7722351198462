import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@mui/material/styles";
import { GetStaticPaths, GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { NextSeo } from "next-seo";
import React, { ReactElement } from "react";
import { renderToString } from "react-dom/server";
import {
  InstantSearchSSRProvider,
  InstantSearchServerState,
  getServerState,
} from "react-instantsearch";

import Chrome from "../../src/components/Chrome";
import ContentProvider, {
  getContent,
} from "../../src/components/ContentProvider";
import InstantSearch from "../../src/components/InstantSearch";
import Search from "../../src/components/Search";
import createTheme from "../../src/createTheme";
import createApolloClient from "../../src/util/createApolloClient";

type IndexPageProps = {
  serverState?: InstantSearchServerState;
};

const IndexPage = ({ serverState }: IndexPageProps): ReactElement => {
  return (
    <InstantSearchSSRProvider {...serverState}>
      <NextSeo canonical="https://findahelpline.com/" />
      <Chrome footer>
        <InstantSearch>
          <Search showAbout />
        </InstantSearch>
      </Chrome>
    </InstantSearchSSRProvider>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  const client = createApolloClient();
  const content = await getContent({
    client,
    hostname: context.params.host.toString(),
    languageCode: context.locale,
  });
  const serverState = await getServerState(
    <ThemeProvider theme={createTheme(content.host)}>
      <ApolloProvider client={client}>
        <ContentProvider {...content}>
          <IndexPage />
        </ContentProvider>
      </ApolloProvider>
    </ThemeProvider>,
    { renderToString },
  );

  return {
    props: {
      ...(await serverSideTranslations(context.locale, [
        "common",
        "topics",
        "regions",
      ])),
      content,
      serverState,
    },
    revalidate: 60 * 60 * 24, // 24 hours
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [{ params: { host: process.env.NEXT_PUBLIC_DEFAULT_HOST } }],
    fallback: "blocking",
  };
};

export default IndexPage;
